<template>
  <!-- 头像 -->
  <img class="custom-avatar w-50 h-50" :shape="shape" :src="src || staffAvatar" />
</template>

<script>
import staffAvatar from "@/assets/images/avatar.png";
export default {
  props: {
    // 图片路径
    src: {
      type: String,
      default: staffAvatar
    },
    // 形状    circle-原型  square-方形
    shape: {
      type: String,
      default: "square"
    }
  },
  data() {
    return {
      staffAvatar
    };
  }
};
</script>

<style lang="scss" scoped>
.custom-avatar {
  &[shape="circle"] {
    border-radius: 999px;
  }
  &[shape="square"] {
    border-radius: 4px;
  }
}
</style>