import http from "@/http";

// 获取岗位列表
export function uploadFile(data) {
  return http({
    url: "/file/upload",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    method: "post",
    data,
  });
}
