<template>
  <a-table
    :rowKey="rowKey"
    :pagination="showPage ? { current: pageNum, pageSize, total } : false"
    :columns="columns"
    :data-source="tableData"
    :bordered="border"
    :loading="loading"
    defaultExpandAllRows
    :expandedRowKeys.sync="defaultExpandedRowKeys"
    :scroll="{ x: true }"
    @change="onTableChange"
  >
    <!-- 标签 -->
    <div slot="tag" slot-scope="tags">
      <a-popover>
        <template slot="content">
          <div class="max-w-400 py-10">
            <a-tag
              style="margin-bottom: 5px; border-radius: 2px"
              v-for="(tag, index) in tags"
              :key="index"
              :color="$colorEnums[index % $colorEnums.length]"
              >{{ tag }}</a-tag
            >
          </div>
        </template>
        <div>
          <a-tag
            style="margin-bottom: 5px; border-radius: 2px"
            v-for="(tag, index) in tags.slice(0, 5)"
            :key="index"
            :color="$colorEnums[index % $colorEnums.length]"
            >{{ tag }}</a-tag
          >
        </div>
      </a-popover>
    </div>

    <!-- 长文本 -->
    <div slot="longText" slot-scope="text">
      <a-tooltip>
        <template slot="title">
          <div class="max-w-400 py-10">{{ text }}</div>
        </template>
        <div v-if="text">
          {{ text.substr(0, 20) }}{{ text.length > 20 ? "..." : "" }}
        </div>
      </a-tooltip>
    </div>

    <!-- 图片 -->
    <div slot="image" slot-scope="img">
      <a-popover placement="right">
        <template slot="content">
          <img class="w-200 h-200" :src="$b.getWholeFileUrl(img)" />
        </template>
        <img class="w-50 h-50 img-cover" :src="$b.getWholeFileUrl(img)" />
      </a-popover>
    </div>

    <!-- 超链接 -->
    <div slot="link" slot-scope="text">
      <a :href="text" target="_blank" class="text-color-blue">{{ text }}</a>
    </div>

    <!-- 操作 -->
    <div slot="option" slot-scope="text, record" class="flex justify-between">
      <template v-if="btnOptions.length <= 3">
        <a
          :style="`color: ${btn.color || $colorEnums[index]}`"
          v-for="(btn, index) in btnOptions"
          :key="index"
          type="link"
          @click="onBtnClick(btn.eventName, record)"
          >{{ btn.label }}</a
        >
      </template>
      <template v-if="btnOptions.length > 3">
        <a
          :style="`color: ${$colorEnums[index]}`"
          v-for="(btn, index) in btnOptions.slice(0, 2)"
          :key="index"
          type="link"
          @click="onBtnClick(btn.eventName, record)"
          >{{ btn.label }}</a
        >
        <a-dropdown>
          <a :style="`color: ${$colorEnums[6]}`">更多</a>
          <a-menu slot="overlay">
            <a-menu-item
              v-for="(btn, index) in btnOptions.slice(2)"
              :key="index"
              @click="onBtnClick(btn.eventName, record)"
            >
              <a>{{ btn.label }}</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </template>
    </div>
  </a-table>
</template>

<script>
import config from "@/config";
export default {
  props: {
    // 表格配置
    columns: {
      type: Array,
      default: () => [],
    },
    // 操作按钮配置  示例：{label:"新增", eventName: "addItem"}
    btnOptions: {
      type: Array,
      default: () => [],
    },
    // 列表数据
    tableData: {
      type: Array,
      default: () => [],
    },
    // 数据总数
    total: {
      type: Number,
      default: 0,
    },
    // 边框
    border: {
      type: Boolean,
      default: false,
    },
    // 表格加载状态
    loading: {
      type: Boolean,
      default: false,
    },
    // 数据唯一值
    rowKey: {
      type: String,
      default: "id",
    },
    // 当前页数
    pageNum: {
      type: Number,
      default: 1,
    },
    // 分页大小
    pageSize: {
      type: Number,
      default: config.pageSize,
    },
    // 树结构使用，元素为需要展开行的rowKey值
    expandedRowKeys: {
      type: Array,
      default: () => [],
    },
    // 分页配置，设置为false则不分页
    showPage: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      defaultExpandedRowKeys: [], // 默认展开的表格行的rowKey集
    };
  },
  created() {
    this.defaultExpandedRowKeys = _.cloneDeep(this.expandedRowKeys);
  },
  methods: {
    // 操作栏点击回调事件
    onBtnClick(eventName, item) {
      this.$emit("onBtnClick", eventName, item);
    },

    // 监听到表格改变
    onTableChange(pagination) {
      this.$emit("update:pageNum", pagination.current);
      this.$emit("onPageChange");
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .ant-table-wrapper {

  .ant-pagination {
    margin-bottom: 0 !important;
  }
}

  /deep/ .ant-table-body {
    overflow-x: auto!important;;
  }
</style>