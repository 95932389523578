<!--
 * @Date: 2023-07-17 14:53:49
 * @Author: liufan
 * @Description:  CustomUpload 上传
-->
<template>
  <div class="inline-block w-full">
    <a-upload
      :list-type="acceptType == 'file' ? 'text' : 'picture-card'"
      :show-upload-list="acceptType != 'video'"
      :beforeUpload="beforeUpload"
      :customRequest="customUpload"
      :file-list="fileList"
      @preview="handlePreview"
      @change="handleChange"
    >
      <template>
        <div v-if="fileList.length < this.limit" class="text-color-brand">
          <a-icon type="diff" class="text-md" />
          <div class="mt-5">{{ uploadName }}</div>
        </div>
      </template>
    </a-upload>

    <a-modal
      :visible="previewVisible"
      :footer="null"
      @cancel="previewVisible = false"
    >
      <img style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import { uploadFile } from "@/http/apis/common";
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    uploadName: {
      type: String,
      default: "上传",
    },
    // 已上传的文件列表
    defaultFileList: {
      type: Array,
      default: () => [],
    },
    // 文件上传上限
    limit: {
      type: Number,
      default: 1,
    },
    // 可上传的文件类型 image-图片 video-视频 file-文件
    acceptType: {
      type: String,
      default: "image",
    },
    // 是否检测文件大小
    checkSize: {
      type: Boolean,
      default: true,
    },
  },
  model: {
    props: "value",
    event: "updateValue",
  },
  data() {
    return {
      fileList: [], // 文件列表
      previewVisible: false, // 是否显示预览弹窗
      previewImage: "",
    };
  },
  computed: {
    fileUrls() {
      return this.fileList.map((item) => item.url || item.response);
    },
  },
  watch: {
    value() {
      this.initDefaultFile();
    },
  },
  mounted() {
    // 初始化时执行一次
    this.initDefaultFile();
  },
  methods: {
    // 初始化已上传文件
    initDefaultFile() {
      this.fileList = []; // 重置上传列表
      if (this.value.length) {
        const defaultFileList = this.value.map((item, index) => {
          let url = item.url || item;
          url = this.$config.fileUrl + url;
          return {
            uid: index + "",
            name: item.name || item.url || item || index + "",
            status: "done",
            url,
          };
        });
        this.fileList.push(...defaultFileList);
      }
    },
    // 上传前对文件作校验
    async beforeUpload(file) {
      let flag = true; // true表示通过校验

      if (this.checkSize) {
        // 图片/视频最大10MB
        if (this.acceptType == "image" || this.acceptType == "video") {
          flag = file.size < 10 * 1024 * 1024;
          flag || this.$message.warning("图片/视频大小不能超过10MB");
        } else if (this.acceptType == "file") {
          // 文件最大20MB
          flag = file.size < 20 * 1024 * 1024;
          flag || this.$message.warning("文件大小不能超过20MB");
        }

        // 未通过校验则直接拦截
        if (flag) {
          const promiseObj = new Promise((resolve, reject) => {
            if (this.acceptType == "image") {
              // 获取图片的像素
              const baseImgUrl = window.URL.createObjectURL(file);
              const imgDom = new Image();
              this.previewImage = imgDom.src = baseImgUrl;
              const timer = setInterval(() => {
                if (imgDom.width) {
                  clearInterval(timer);

                  if (imgDom.width * imgDom.height <= 1555200) {
                    resolve(true);
                  } else {
                    this.$message.warning(
                      "图片像素过大，建议尺寸1000*1000以内"
                    );
                    reject(false);
                  }
                }
              });
            } else if (this.acceptType == "video") {
              // 获取视频的长度
              const video = document.createElement("video");
              video.src = URL.createObjectURL(file);
              const that = this;

              video.oncanplay = function () {
                const duration = video.duration;
                if (duration <= 30) {
                  resolve(true);
                } else {
                  that.$message.warning("视频时长不能超过30秒");
                  reject(false);
                }
              };
            } else {
              resolve(true);
            }
          });

          flag = await promiseObj;
          if (!flag) {
            return Promise.reject();
          }
        } else {
          return Promise.reject();
        }
      }
    },

    // 自定义上传逻辑
    customUpload(data) {
      const file = data.file;
      const formData = new FormData();
      formData.append("uploadFile", file);

      // 执行上传
      uploadFile(formData)
        .then((res) => {
          data.onSuccess(res, data); // 通知组件上传成功
        })
        .catch(() => {
          data.onError(); // 通知组件上传失败
        });
    },
    // 文件状态改变
    handleChange({ file, fileList }) {
      // 如果此时文件数量超过上限，则从文件列表的头部截取掉对应数量的文件（后上传的替代先上传的）
      if (fileList.length > this.limit) {
        fileList.splice(0, fileList.length - this.limit);
      }
      this.fileList = fileList;

      if (file.status != "uploading") {
        this.$emit("onChange", this.fileList);
        this.$emit("updateValue", this.fileUrls);
      }
    },
    // 重置上传组件
    resetUpload() {
      this.fileList = [];
    },

    // 预览图片
    handlePreview(file) {
      switch (this.acceptType) {
        case "image":
          this.previewImage = file.thumbUrl || file.url;
          this.previewVisible = true;
          break;

        case "file":
          window.open(file.thumbUrl || file.url, "_blank");
          break;
      }
    },
    handleCancel() {
      this.previewVisible = false;
    },
  },
};
</script>

<style></style>
