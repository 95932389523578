/* 全局参数配置 */

module.exports = {
  // 示例
  title: "黑猫农信销售管理平台",
  pageSize: 15,
  AmapKey: "", // 高德地图应用key（仅为示例）https://app.cdnxbb.com
  baseUrl: "http://market.cdnxbb.com/market-api/", // 接口地址
  fileUrl: "http://market.cdnxbb.com/market-api/img/", // 接口地址
};
