// 示例
import http from "@/http";

// 获取客户模板列表
export function getCustomer(params) {
  return http({
    url: "/admin/customer/page",
    method: "get",
    params,
  });
}
// 获取全部客户模板
export function getAllTemplate(params) {
  return http({
    url: "/app/data/customer/form/all",
    method: "get",
    params,
  });
}

// 新增竞争
export function addCustomer(data) {
  return http({
    url: "/admin/customer/add",
    method: "post",
    data,
  });
}
// 编辑竞争
export function editCustomer(data) {
  return http({
    url: "/admin/customer/update",
    method: "post",
    data,
  });
}
// 删除竞争
export function delCustomer(data) {
  return http({
    url: "/admin/customer/delete",
    method: "post",
    data,
  });
}
