/* 全局工具类函数 */

/* 日期格式化 */
function dateFormat(fmt, date) {
  var o = {
    "M+": date.getMonth() + 1, //月份
    "d+": date.getDate(), //日
    "h+": date.getHours(), //小时
    "m+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
}

function formatDate(date, fmt = "yyyy-MM-dd hh:mm:ss") {
  // 拦截空值
  if (!date && date !== 0) {
    return null;
  }

  // 判断是否为日期对象，不是则转为日期
  if (typeof date == "string" || typeof date == "number") {
    date = new Date(date);
  }

  return dateFormat(fmt, date);
}

// 渲染表格按钮 that-this  btnOptions-按钮配置{name:"按钮名称", eventName: "函数名称", className: "text-color-red"}  record-参数
import colorEnum from "@/enums/color"; // 可上传文件类型
import { h } from "vue";
function renderTableBtn(btnOptions, record, otherProps) {
  let btnDom = null;
  if (btnOptions.length > 3) {
    btnDom = (
      <div>
        {btnOptions.slice(0, 2).map((btn, index) => (
          <a
            class={"mr-10 " + (btn.className || "")}
            style={btn.className ? "" : `color:${colorEnum[index]}`}
            onClick={btn.callback.bind(this, record, btn.otherProps)}
          >
            {btn.name}
          </a>
        ))}
        <a-dropdown>
          <a class="text-color-orange">更多</a>
          <a-menu slot="overlay">
            {btnOptions.slice(2).map((btn) => (
              <a-menu-item>
                <a onClick={btn.callback.bind(this, record, btn.otherProps)}>{btn.name}</a>
              </a-menu-item>
            ))}
          </a-menu>
        </a-dropdown>
      </div>
    );
  } else {
    btnDom = (
      <div>
        {btnOptions.map((btn, index) => (
          <a
            class={"mr-10 " + (btn.className || "")}
            style={btn.className ? "" : `color:${colorEnum[index]}`}
            onClick={btn.callback.bind(this, record, btn.otherProps)}
          >
            {btn.name}
          </a>
        ))}
      </div>
    );
  }

  return btnDom;
}

export default {
  formatDate,
  renderTableBtn,
};
