import Vue from "vue";
import VueRouter from "vue-router";
import StaticRoutes from "./staticRoutes"; // 静态路由

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "hash",
  routes: StaticRoutes,
});

export default router;