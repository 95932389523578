import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store";
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

Vue.config.productionTip = false; // 阻止vue在启动时生成生产提示

Vue.use(Antd);

import config from "@/config";
Vue.prototype.$config = config;

import business from "@/utils/business";
Vue.prototype.$b = business;
import utils from "@/utils/util";
Vue.prototype.$u = utils;

import "@/components/_global.js";

import colorEnum from "@/enums/color"; // 可上传文件类型
Vue.prototype.$colorEnum = colorEnum;

import _ from "lodash";
Vue.prototype._ = _;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
