import http from "@/http";

// 获取员工列表
export function getStaff(params) {
  return http({
    url: "/admin/saleMan/page",
    method: "get",
    params,
  });
}
// 获取员工列表
export function getUnSelectStaff() {
  return http({
    url: "/admin/saleGroup/availableMember",
    method: "get",
  });
}
// 新增员工
export function addStaff(data) {
  return http({
    url: "/admin/saleMan/add",
    method: "post",
    data,
  });
}
// 编辑员工
export function editStaff(data) {
  return http({
    url: "/admin/saleMan/update",
    method: "post",
    data,
  });
}
// 删除员工
export function delStaff(data) {
  return http({
    url: "/admin/saleMan/delete",
    method: "post",
    data,
  });
}
// 禁用员工
export function disableStaff(data) {
  return http({
    url: "/admin/saleMan/disable",
    method: "post",
    data,
  });
}
// 启用员工
export function enableStaff(data) {
  return http({
    url: "/admin/saleMan/enable",
    method: "post",
    data,
  });
}

// 重置员工密码
export function resetStaff(data) {
  return http({
    url: "/admin/saleMan/resetPassword",
    method: "post",
    data,
  });
}
