// 示例
import http from "@/http";

// 获取字典组列表
export function getCodeGroup() {
  return http({
    url: "/admin/dict/list",
    method: "get",
  });
}
// 添加字典组
export function addCodeGroup(data) {
  return http({
    url: "/admin/dict/save",
    method: "post",
    data,
  });
}
// 编辑字典组
export function editCodeGroup(data) {
  return http({
    url: "/admin/dict/update",
    method: "post",
    data,
  });
}
// 删除字典组
export function delCodeGroup(data) {
  return http({
    url: "/admin/dict/delete",
    method: "post",
    data,
  });
}

// 获取字典列表
export function getCode(params) {
  return http({
    url: "/admin/dict/item/list",
    method: "get",
    params,
  });
}
// 新增字典项
export function addCode(data) {
  return http({
    url: "/admin/dict/item/save",
    method: "post",
    data,
  });
}
// 编辑字典项
export function editCode(data) {
  return http({
    url: "/admin/dict/item/update",
    method: "post",
    data,
  });
}
// 删除字典项
export function delCode(data) {
  return http({
    url: "/admin/dict/item/delete",
    method: "post",
    data,
  });
}

// 获取字典树列表
export function getCodeTree() {
  return http({
    url: "/admin/dict/tree",
    method: "get",
  });
}