import store from "@/store";
import router from "@/router";
import { getInfo, logout } from "@/http/apis/passport";
import { getCodeTree } from "@/http/apis/dictionary";
import { getAllTemplate } from "@/http/apis/customer";
import config from "@/config";

// 获取用户信息并存入vuex
function updateUserinfo() {
  getInfo().then((res) => {
    store.commit("setUserinfo", res);
  });
}

// 获取岗位/部门/用户性质列表 type: position-岗位 depart-部门 userType-用户性质 taskType-任务类型 taskUnit-任务目标单位 rewardType-奖惩类型
function updateDictionary(type) {
  getCodeTree().then(res => {
    res.forEach((item, index) => {
      switch (item.code) {
        case "position":
          store.commit("setPosition", item.children);
          break;
        case "depart":
          store.commit("setDepart", item.children);
          break;
        case "userType":
          store.commit("setUserType", item.children);
          break;
        case "taskType":
          store.commit("setTaskType", item.children);
          break;
        case "taskUnit":
          store.commit("setTaskUnit", item.children);
          break;
        case "rewardType":
          store.commit("setRewardType", item.children);
          break;
      }
    })
  });
}

// 获取用户表单模板数据
function getFormTemlpate() {
	getAllTemplate().then(res => {
		store.commit('setFormTemlpate', res);
	})
}

// 退出登陆
function logOut() {
  logout().then(() => {
    store.commit("setUserinfo", {});
    localStorage.removeItem("token");
    router.replace("/login");

    location.reload();
  });
}

// 根据枚举值查询名称
function getEnumNameByVal(type, value) {
  if (!isNaN(value - 0)) {
    value -= 0;
  }

  // 性别
  if (type == "sex") {
    switch (value) {
      case 0:
        return "男";
      case 1:
        return "女";
      default:
        return "未知";
    }
  }

  // 周期
  if (type == "cycle") {
    switch (value) {
      case "DAY":
        return "日";
      case "WEEK":
        return "周";
      case "MONTH":
        return "月";
      case "QUARTER":
        return "季";
      case "YEAR":
        return "年";
    }
  }

  return value;
}

// 根据字典项编码查询字典项名称 type: position-岗位 depart-部门 userType-用户性质 taskType-任务类型 taskUnit-任务目标单位 rewardType-奖惩类型
function getCodeName(type, value) {
  const list = [...store.state[type]];

  const codeList = list.map((item) => item.code);
  const index = codeList.indexOf(value);

  let res = value;
  if (index >= 0) {
    res = list[index].name;
  }

  return res;
}

// 补全图片文件地址
function getWholeFileUrl(path) {
  let res;
  if (path) {
    if (path.includes("http")) {
      res = path;
    } else {
      res = config.fileUrl + path;
    }
  }

  return res;
}

export default {
  logOut,
  updateUserinfo,
  updateDictionary,
  getFormTemlpate,
  getEnumNameByVal,
  getCodeName,
  getWholeFileUrl,
};
