import Vue from "vue";
import axios from "axios";
import store from "@/store";
import router from "@/router";
import config from "@/config";
import { message } from "ant-design-vue";

let baseURL = "/market-api";

// 开发环境
if(process.env.NODE_ENV == "development"){
  baseURL = "/api";
}

const http = axios.create({
  baseURL,
  timeout: 60000,
  headers: {
    "Accept": "application/json, text/plain, */*",
    // "content-type": "application/json",
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  },
});

// 请求拦截
http.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    const token = localStorage.getItem("token");
    token && (config.headers.token = token);

    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 响应拦截
http.interceptors.response.use(
  function (res) {
    // 不需要经过拦截器的接口URL
    const whiteList = [];
    if (whiteList.includes(res.config.url)) {
      return res;
    }

    res = res.data;
    res.code -= 0; // 部分接口code是字符串类型，统一转一下code格式为数字

    switch (res.code) {
      case 200: // 成功
        return res.data;

      case 401: // 登陆过期
        router.replace("/login");
        message.error("身份已过期，请重新登录");
        return Promise.reject();

      default:
        message.error(res.message || res.msg || "接口错误或超时");
        return Promise.reject();
    }
  },
  function (error) {
    // 响应错误
    message.error(error.message || res.msg || "接口错误或超时");
    return Promise.reject(error);
  }
);

Vue.prototype.$axios = axios;
Vue.prototype.$http = http;
export default http;