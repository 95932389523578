<template>
  <div class="h-full flex justify-center align-items-center bg-color-brand">
    <div
      class="form-warp flex align-items-center box-shadow bg-color-white overflow-hidden"
    >
      <img class="w-450" src="@/assets/images/login-img1.png" />

      <div class="px-50">
        <div class="mb-50 text-sm font-bold">{{ $config.title }}</div>
        <a-form-model
          class="w-300"
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
        >
          <a-form-model-item has-feedback label="帐号" prop="username">
            <a-input v-model="ruleForm.username" autocomplete="off" />
          </a-form-model-item>
          <a-form-model-item has-feedback label="密码" prop="password">
            <a-input
              v-model="ruleForm.password"
              type="password"
              autocomplete="off"
            />
          </a-form-model-item>
          <a-button class="w-full mt-40" type="primary" @click="login"
            >登录</a-button
          >
        </a-form-model>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { login } from "@/http/apis/passport";
export default {
  data() {
    return {
      ruleForm: {},
      rules: {
        username: [
          {
            validator: (rule, value) => !!value,
            trigger: "change",
            message: "请输入账号",
          },
        ],
        password: [
          {
            validator: (rule, value) => !!value,
            trigger: "change",
            message: "请输入密码",
          },
        ],
      },
    };
  },

  created() {
    document.onkeypress = (e) => {
      let keycode = e.keyCode;
      if (keycode == 13) {
        this.login();
      }
    };
  },

  methods: {
    ...mapActions(["getAccessRoutes", "getPermissionInfo"]),

    // 登陆
    login() {
      this.$refs.ruleForm.validate().then(() => {
        login({
          ...this.ruleForm,
          userType: "SYSTEM", // SYSTEM-后台系统 APP-APP应用
        }).then((res) => {
          localStorage.setItem("token", res.token);
          this.$message.success("登录成功");
          this.$b.updateUserinfo();
          this.$b.updateDictionary(); // 获取字典信息
          this.$b.getFormTemlpate(); // 获取表单模板
          this.$router.push("/");
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.form-warp {
  border-radius: 10px 60px 10px 60px;
}
</style>