<template>
  <!-- 性别图标 -->
  <div class="inline-block" v-if="gender || gender === 0">
    <a-icon v-if="gender == 0" type="man" style="color: #13a2e8" />
    <a-icon v-else type="woman" style="color: #f753b2" />
  </div>
</template>

<script>
export default {
  props: {
    // 性别值 0
    gender: {
      type: [String, Number],
      default: 0,
    },
  },
};
</script>

<style>
</style>