import http from "@/http";

// 登录
export function login(params) {
  return http({
    url: "/login",
    method: "post",
    params,
  });
}

// 退出登录
export function logout() {
  return http({
    url: "/logout",
    method: "post",
  });
}

// 获取登录用户信息
export function getInfo() {
  return http({
    url: "/user/info",
    method: "get",
  });
}