<template>
    <div class="h-full">
        <a-layout class="layout">
            <a-layout-sider class="layout-left">
                <div class="wrap-title flex flex-col align-items-center px-25 pt-25">
                    <img class="w-100 rounded-xs" src="@/assets/images/logo.jpg" alt />
                    <div class="title mt-20 leading-none">{{ $config.title }}</div>
                </div>
                <a-menu theme="dark" :open-keys.sync="openKeys" :selectedKeys="selectedKeys" mode="inline"
                    @select="onMenuSelect">
                    <a-sub-menu v-for="menu in menuData" :key="menu.path">
                        <div slot="title" class="pr-25 flex align-items-center">
                            <i class="iconfont text-xs-sub" :class="`icon-${menu.icon}`"></i>
                            <div class="flex-grow text-center">{{ menu.title }}</div>
                        </div>
                        <a-menu-item v-for="menuChild in menu.children" :key="menuChild.path">{{ menuChild.title
                        }}</a-menu-item>
                    </a-sub-menu>
                </a-menu>
            </a-layout-sider>
            <a-layout class="layout-right-wrap">
                <div class="layout-right">
                    <a-layout-header class="layout-header justify-between">
                        <a-breadcrumb>
                            <a-breadcrumb-item>
                                <a-icon type="unordered-list" />
                            </a-breadcrumb-item>
                            <template>
                                <a-breadcrumb-item v-for="title in $route.meta.title.split('/')" :key="title">
                                    <a>{{ title }}</a>
                                </a-breadcrumb-item>
                            </template>
                        </a-breadcrumb>

                        <a-dropdown :trigger="['click']">
                            <div class="flex align-items-center text-color-brand pointer">
                                <a>{{ userinfo.username }}</a>
                                <a-icon class="mx-5" :size="20" type="caret-down" />
                                <CustomAvatar class="w-40 h-40" :src="userinfo.img" />
                            </div>
                            <a-menu slot="overlay">
                                <a-menu-item key="2" @click="$b.logOut">退出登录</a-menu-item>
                            </a-menu>
                        </a-dropdown>
                    </a-layout-header>
                    <a-layout-content class="layout-content px-20">
                        <router-view></router-view>
                    </a-layout-content>
                </div>
            </a-layout>
        </a-layout>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState(["routeData", "userinfo"])
    },
    data() {
        return {
            openKeys: ["/workbench"], // 展示的菜单组
            selectedKeys: [], // 选中的菜单项
            menuData: [
                {
                    title: "工作台",
                    icon: "",
                    path: "/workbench",
                    children: [
                        {
                            title: "任务管理",
                            path: "/workbench/task",
                        },
                        {
                            title: "员工管理",
                            path: "/workbench/staff",
                        },
                        {
                            title: "奖惩管理",
                            path: "/workbench/reward",
                        },
                        {
                            title: "小组管理",
                            path: "/workbench/group",
                        },
                        {
                            title: "竞争管理",
                            path: "/workbench/compete",
                        },
                        {
                            title: "客户模板管理",
                            path: "/workbench/customer",
                        },
                        {
                            title: "推广数据管理",
                            path: "/workbench/promotion",
                        },
                         {
                            title: "字典管理",
                            path: "/workbench/dictionary",
                        },
                    ]
                }
            ]
        }
    },
    mounted() {
        this.selectedKeys = [this.$route.fullPath];
    },
    watch: {
        $route() {
            this.selectedKeys = [this.$route.fullPath];
        },
    },
    methods: {
        // 点击菜单
        onMenuSelect(item) {
            this.$router.push(item.key);
        },
    }
}
</script>

<style lang="less" scoped>
.layout {
    height: 100%;

    .ant-layout {
        height: 100%;
    }

    .wrap-title {
        margin-bottom: 10px;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.35);

        .title {
            padding-bottom: 30px;
            font-size: 18px;
            text-align: center;
            color: #fff;
            letter-spacing: 0.05em;
            line-height: 1.5;
        }
    }

    .layout-header {
        margin-bottom: 20px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        background-color: #fff;
        border-bottom: 1px solid #e8e8e8;
    }

    .layout-content {
        overflow: auto;
        height: calc(100vh - 104px);
        padding-bottom: 20px;
      }
}
</style>