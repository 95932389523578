<!-- 检索组件 -->
<template >
  <div
    v-if="options && options.length"
    class="p-20 bg-color-white rounded-base box-shadow"
  >
    <a-form-model
      ref="searchForm"
      :model="formData"
      :labelCol="{ md: 11, lg: 9, xl: 7, xxl: 5 }"
      :wrapperCol="{ md: 13, lg: 15 }"
    >
      <a-row :gutter="16">
        <a-col
          :span="6"
          v-for="formItem in options.slice(0, 3)"
          :key="formItem.key"
        >
          <a-form-model-item :label="formItem.label" :key="formItem.key">
            <!-- 输入框 -->
            <template v-if="formItem.type == 'text'">
              <a-input
                v-model="formData[formItem.key]"
                :placeholder="formItem.placeholder"
              ></a-input>
            </template>

            <!-- 下拉框 -->
            <template v-if="formItem.type == 'select'">
              <a-select
                v-model="formData[formItem.key]"
                :placeholder="formItem.placeholder"
                allowClear
              >
                <a-select-option
                  v-for="selectItem in formItem.opt"
                  :key="selectItem.value"
                  :value="selectItem.value"
                  >{{ selectItem.label }}</a-select-option
                >
              </a-select>
            </template>

            <!-- 日期 -->
            <template v-if="formItem.type == 'date'">
              <a-date-picker
                v-model="formData[formItem.key]"
                :placeholder="formItem.placeholder"
              />
            </template>

            <!-- 日期范围 -->
            <template v-if="formItem.type == 'dateRange'">
              <a-range-picker
                ref="dateRange"
                v-model="formData[formItem.key]"
                :placeholder="formItem.placeholder"
              />
            </template>

            <!-- 基础信息选择组件 -->
            <template v-if="formItem.type == 'basic'">
              <SelectBasic
                v-model="formData[formItem.key]"
                :type="formItem.searchType"
              ></SelectBasic>
            </template>
          </a-form-model-item>
        </a-col>

        <a-col :span="6" style="height: 40px">
          <div class="h-full flex align-items-center">
            <a-button html-type="submit" type="primary" @click="onSearch"
              >查询</a-button
            >
            <a-button class="ml-10" @click="onReset">重置</a-button>
            <a-button
              v-if="options.length > 3"
              type="link"
              @click="showMore = !showMore"
            >
              {{ showMore ? "收起" : "展开" }}
              <a-icon
                :type="showMore ? 'up' : 'down'"
                style="margin-left: 5px; font-size: 12px"
              />
            </a-button>
          </div>
        </a-col>

        <a-col
          v-show="showMore"
          :span="6"
          v-for="formItem in options.slice(3)"
          :key="formItem.key"
        >
          <a-form-model-item :label="formItem.label" :key="formItem.key">
            <!-- 输入框 -->
            <template v-if="formItem.type == 'text'">
              <a-input
                v-model="formData[formItem.key]"
                :placeholder="formItem.placeholder"
              ></a-input>
            </template>

            <!-- 下拉框 -->
            <template v-if="formItem.type == 'select'">
              <a-select
                v-model="formData[formItem.key]"
                :placeholder="formItem.placeholder"
                allowClear
              >
                <a-select-option
                  v-for="selectItem in formItem.opt"
                  :key="selectItem.value"
                  :value="selectItem.value"
                  >{{ selectItem.label }}</a-select-option
                >
              </a-select>
            </template>

            <!-- 日期 -->
            <template v-if="formItem.type == 'date'">
              <a-date-picker
                v-model="formData[formItem.key]"
                :placeholder="formItem.placeholder"
              />
            </template>

            <!-- 日期范围 -->
            <template v-if="formItem.type == 'dateRange'">
              <a-range-picker
                ref="dateRange"
                v-model="formData[formItem.key]"
                :placeholder="formItem.placeholder"
              />
            </template>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    // 搜索表单配置
    options: {
      type: Array,
      default: () => [],
    },
    // 默认查询条件
    searchData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      formData: {}, // 搜索参数
      showMore: false, // 显示更多
    };
  },
  created() {
    this.formData = this.complieDateToArray();
  },
  methods: {
    // 执行查询
    onSearch() {
      this.$emit("update:searchData", this.complieDateToString()); // 自动更新绑定的参数
      this.$emit("onSearch"); // 通知父组件执行查询操作
    },

    // 重置查询条件
    onReset() {
      this.formData = {};
      this.$refs.searchForm.resetFields();
      this.onSearch();
    },

    // 处理日期范围数据为数组（用于组件回显）
    complieDateToArray() {
      const dataInfo = { ...this.searchData };
      this.options.forEach((item) => {
        if (item.type == "dateRange") {
          const { key, endKey } = item;
          const startTime = dataInfo[key];
          const endTime = dataInfo[endKey];

          // 若参数对象存在默认的日期范围
          if (startTime && endTime) {
            dataInfo[key] = [moment(startTime), moment(endTime)];
          } else {
            dataInfo[key] = [];
          }
        }
      });

      return dataInfo;
    },

    // 处理日期范围数据为字符串（用于页面发送请求）
    complieDateToString() {
      const dataInfo = { ...this.formData };
      this.options.forEach((item) => {
        const { key, endKey } = item;

        if (item.type == "dateRange") {
          const timeRange = dataInfo[key];
          // 若参数对象存在默认的日期范围
          if (timeRange && timeRange.length == 2) {
            dataInfo[endKey] = timeRange[1].format("YYYY-MM-DD 23:59:59");
            dataInfo[key] = timeRange[0].format("YYYY-MM-DD 00:00:00");
          } else {
            delete dataInfo[key];
            delete dataInfo[endKey];
          }
        } else if (item.type == "date") {
          if (dataInfo[key]) {
            dataInfo[key] = dataInfo[key].format("YYYY-MM-DD 00:00:00");
          }
        }
      });

      return dataInfo;
    },
  },
};
</script>

<style lang="less" scoped>
.ant-form-item {
  margin-bottom: 0;
}
</style>