/* 定义不受权限控制的静态路由 */

import Login from "../views/Login.vue";
import BasicLayout from "../views/layout/BasicLayout.vue";
import RouterView from "../views/layout/RouterView.vue";

const staticRoutes = [
  // 登陆
  {
    path: "/login",
    name: "login",
    component: Login,
  },

  {
    path: "/",
    redirect: "/workbench/task",
    component: BasicLayout,
    children: [
      // 工作台
      {
        path: "/workbench",
        name: "workbench",
        redirect: "/workbench/task",
        component: RouterView,
        meta: { title: "工作台" },
        children: [
          // 任务管理
          {
            path: "/workbench/task",
            name: "task",
            component: () => import("@/views/workbench/task.vue"),
            meta: { title: "工作台/任务管理" },
          },
          // 员工管理
          {
            path: "/workbench/staff",
            name: "staff",
            component: () => import("@/views/workbench/staff.vue"),
            meta: { title: "工作台/员工管理" },
          },
          // 奖惩管理
          {
            path: "/workbench/reward",
            name: "reward",
            component: () => import("@/views/workbench/reward.vue"),
            meta: { title: "工作台/奖惩管理" },
          },
          // 小组管理
          {
            path: "/workbench/group",
            name: "group",
            component: () => import("@/views/workbench/group.vue"),
            meta: { title: "工作台/小组管理" },
          },
          // 竞争管理
          {
            path: "/workbench/compete",
            name: "compete",
            component: () => import("@/views/workbench/compete.vue"),
            meta: { title: "工作台/竞争管理" },
          },
          // 客户模板管理
          {
            path: "/workbench/customer",
            name: "customer",
            component: () => import("@/views/workbench/customer.vue"),
            meta: { title: "工作台/客户模板管理" },
          },
          // 推广数据管理
          {
            path: "/workbench/promotion",
            name: "promotion",
            component: () => import("@/views/workbench/promotion.vue"),
            meta: { title: "工作台/推广数据管理" },
          },
          // 字典管理
          {
            path: "/workbench/dictionary",
            name: "dictionary",
            component: () => import("@/views/workbench/dictionary.vue"),
            meta: { title: "工作台/字典管理" },
          },
        ],
      },
    ],
  },
];

export default staticRoutes;
