import http from "@/http";

// 获取小组列表
export function getGroup(params) {
  return http({
    url: "/admin/saleGroup/page",
    method: "get",
    params,
  });
}
// 新增小组
export function addGroup(data) {
  return http({
    url: "/admin/saleGroup/add",
    method: "post",
    data,
  });
}
// 编辑小组
export function editGroup(data) {
  return http({
    url: "/admin/saleGroup/update",
    method: "post",
    data,
  });
}
// 删除小组
export function delGroup(data) {
  return http({
    url: "/admin/saleGroup/delete",
    method: "post",
    data,
  });
}
