<!--
 * @Date: 2023-07-25 11:34:00
 * @Author: liufan
 * @Description:  SelectBasic
-->
<template>
  <!-- 基础信息选择组件 -->
  <div>
    <!-- 普通下拉框 -->
    <a-select
      v-if="controlType == 'select'"
      :mode="multiple ? 'multiple' : 'default'"
      v-model="selectValue"
      allow-clear
      :placeholder="placeholder"
      :disabled="disabled"
      @change="onChange"
    >
      <a-select-option
        v-for="(item, index) in selectOptions"
        :key="index"
        :value="item.value"
        >{{ item.label }}</a-select-option
      >
    </a-select>
    <!-- 树状下拉框 -->
    <a-tree-select
      v-if="controlType == 'treeSelect'"
      v-model="selectValue"
      :placeholder="placeholder"
      :treeData="selectOptions"
      :replaceFields="{ title: 'label', children: 'childrenList' }"
      :disabled="disabled"
      allow-clear
      @change="onChange"
    ></a-tree-select>

    <!-- 级联选择器 -->
    <a-cascader
      v-if="controlType == 'cascader'"
      v-model="selectValue"
      :options="selectOptions"
      :changeOnSelect="true"
      :disabled="disabled"
      :placeholder="placeholder"
      @change="onChange"
    />
  </div>
</template>

<script>
import { getCodeGroup } from "@/http/apis/dictionary";
import { getStaff, getUnSelectStaff } from "@/http/apis/staff";
import { getGroup } from "@/http/apis/group";
import store from "@/store";
export default {
  props: {
    // 默认的下拉选项值
    defaultSelection: {
      type: [Array],
      default: () => [],
    },
    // 组件绑定值
    value: {
      type: [String, Number, Array],
    },
    // 查询类型  codeGroup-字典  position-岗位 depart-部门 taskType-任务类型 userType-用户性质 staff-员工 unSelectStaff-未被选择（加入小组）员工 group-小组 customerSource-客户来源 package-服务套餐
    type: {
      type: String,
      default: "codeGroup",
    },
    // 查询时需要带的参数
    requestParams: {
      type: Object,
      default: () => {},
    },
    // 占位符
    placeholder: {
      type: String,
      default: "请选择",
    },
    // 数据中的value字段名
    keyName: {
      type: String,
    },
    // 是否多选
    multiple: {
      type: Boolean,
      default: false,
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: "value",
    event: "updateValue",
  },
  data() {
    return {
      selectValue: undefined, // 下拉框绑定值
      // 选项列表
      selectOptions: [],
      controlType: "", // 控件类型 select-下拉框 treeSelect-树状下拉框 cascader-级联选择器
    };
  },
  watch: {
    // 监听外部传参改变
    value: {
      handler(val) {
        this.selectValue = val;
      },
      immediate: true,
    },
  },
  mounted() {
    // 1.初始化默认赋值
    this.selectValue = this.value;

    // 2.确认控件类型
    this.controlType = this.getControlType();

    // 3.初始化进行一次搜索
    this.handleSearch();
  },
  methods: {
    // 执行搜索
    async handleSearch() {
      let res;

      switch (this.type) {
        case "codeGroup": // 字典
          res = await getCodeGroup();
          res = res.map((item) => ({
            label: item.name,
            value: item.code,
          }));
          break;

        case "position": // 岗位
        case "depart": // 部门
        case "taskType": // 任务类型
        case "taskUnit": // 任务目标单位
        case "userType": // 用户性质
          res = [...store.state[this.type]];
          res = res.map((item) => ({
            label: item.name,
            value: item.code,
          }));
          break;

        case "staff": // 员工
          res = await getStaff({ pageSize: 9999 });
          res = res.records.map((item) => ({
            label: item.realName,
            value: this.keyName ? item[this.keyName] : item.id,
          }));
          break;

        case "unSelectStaff": // 员工
          res = await getUnSelectStaff();
          res = res.map((item) => ({
            label: item.realName,
            value: this.keyName ? item[this.keyName] : item.id,
          }));
          console.log("组件内", this.defaultSelection)
          res = [...new Set([...res, ...this.defaultSelection])];

          break;

        case "group": // 小组
          res = await getGroup({ pageSize: 9999 });
          res = res.records.map((item) => ({
            label: item.name,
            value: this.keyName ? item[this.keyName] : item.id,
          }));
          break;

        case "package": // 服务套餐
          res = await choiceList({
            pageNum: 1,
            pageSize: 100,
            ...this.requestParams,
          });
          res = res.rows.map((item) => ({
            label: item.combosName,
            value: item.id,
          }));
          break;

        case "customerSource": // 客户来源
          res = await getCustomerSource();
          res = this.formatDataKey(res, {
            label: "sourceName",
            value: "sourceId",
            children: "childrenList",
            key: "sourceId",
          });
          break;
      }
      this.selectOptions = res;
    },

    // 选中回调
    onChange(val) {
      this.$emit("updateValue", val);
      this.$emit("change", val);
    },

    // 判断当前的控件类型
    getControlType() {
      switch (this.type) {
        // 普通下拉框
        case "codeGroup":
        case "position":
        case "depart":
        case "userType":
        case "taskType":
        case "staff":
        case "unSelectStaff":
        case "taskUnit":
        case "group":
          return "select";

        // 树状下拉框
        case "goodsType":
        case "customerSource":
          return "treeSelect";

        // 级联选择器
        case "hospitalDepart":
          return "cascader";
      }
    },

    // 格式化数据字段
    formatDataKey(list, fieldNames) {
      let newList = [];
      const label = fieldNames.label;
      const value = fieldNames.value;
      const children = fieldNames.children;
      const key = fieldNames.key;

      newList = list.map((item) => {
        const newItem = {
          label: item[label],
          value: item[value],
          children: item[children],
        };
        key && (newItem.key = item[key]);

        if (newItem.children && newItem.children.length) {
          newItem.children = this.formatDataKey(newItem.children, fieldNames);
        }

        return newItem;
      });

      return newList;
    },
  },
};
</script>

<style></style>
