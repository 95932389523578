import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userinfo: {}, // 账号信息
    position: [], // 岗位列表
    depart: [], // 部门列表
    userType: [], // 用户性质
    taskType: [], // 任务类型
    taskUnit: [], // 任务目标单位
    rewardType: [], // 奖惩类型
    formTemlpate: [], // 表单模板
  },
  getters: {},
  mutations: {
    setUserinfo(state, value) {
      state.userinfo = value;
    },
    setPosition(state, value) {
      state.position = value;
    },
    setDepart(state, value) {
      state.depart = value;
    },
    setUserType(state, value) {
      state.userType = value;
    },
    setTaskType(state, value) {
      state.taskType = value;
    },
    setTaskUnit(state, value) {
      state.taskUnit = value;
    },
    setRewardType(state, value) {
      state.rewardType = value;
    },
    setFormTemlpate(state, value) {
			state.formTemlpate = value;
		},
  },
  actions: {},
});
