<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <router-view />
    </a-config-provider>
  </div>
</template>

<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";

export default {
  name: "App",
  components: {},
  data() {
    return {
      locale: zhCN,
    };
  },
  created() {
    const token = localStorage.getItem("token");
    if (token) {
      this.$b.updateUserinfo(); // 获取用户信息
      this.$b.updateDictionary(); // 获取字典信息
      this.$b.getFormTemlpate(); // 获取表单模板
    } else {
      this.$router.replace("/login");
    }
  },
};
</script>

<style lang="scss">
@import "@/style/index.scss";
</style>